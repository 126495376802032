
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-overpass-layer";
import "leaflet-overpass-layer/dist/OverPassLayer.css";
import "leaflet.locatecontrol";
import "leaflet.polylinemeasure";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "@fortawesome/fontawesome-free/js/solid.js";
import "@fortawesome/fontawesome-free/js/fontawesome.js";
import "leaflet.awesome-markers";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.css";
import { AutobahnLayer } from "../AutobahnLayer";

// Fix missing icon cause by webpack
// See https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
// This fix works only partly as L.Icon.Default cannot be used (causes runtime error)
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default ({
  name: "Map",
  data() {
    return {
      // We do not use a member 'map' here because of the problems discovered as
      // described in https://stackoverflow.com/a/66516334
    };
  },
  methods: {},
  mounted() {
    // For the reason to disable zoom animations, see https://stackoverflow.com/a/66516334
    // For tap: false see https://github.com/domoritz/leaflet-locatecontrol#safari-does-not-work-with-leaflet-171
    const map = L.map("map", { zoomAnimation: false, tap: false, maxZoom: 19 }).setView(
      [49.4489, 11.0783],
      11
    );

    const attrOsm =
      'Map data &copy; <a href="http://openstreetmap.org/">OpenStreetMap</a> contributors';
    const attrOverpass =
      'POI via <a href="http://www.overpass-api.de/">Overpass API</a>';

    const osmLayer = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution: [attrOsm, attrOverpass].join(", "),
      maxZoom: 19,
    });

    const osmDeLayer = L.tileLayer("https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png", {
      attribution: [attrOsm, attrOverpass].join(", "),
      maxZoom: 19,
    });
    osmDeLayer.addTo(map);

    const osmPublicTransportLayer = L.tileLayer("https://tile.memomaps.de/tilegen/{z}/{x}/{y}.png", {
      attribution: [attrOsm, attrOverpass].join(", "),
      maxZoom: 17,
    });

    const attrThunderforest =
      'Maps &copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, Data <a href="http://openstreetmap.org/">OpenStreetMap</a> contributors';    

    const thunderforestOutdoor = L.tileLayer("https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=6d03ad810031442c8f14437fd26551d4", {
      attribution: [attrThunderforest, attrOverpass].join(", "),
      maxZoom: 19,
    });

    const thunderforestOpenCycleMap = L.tileLayer("https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=6d03ad810031442c8f14437fd26551d4", {
      attribution: [attrThunderforest, attrOverpass].join(", "),
      maxZoom: 19,
    });

    const thunderforestLandscape = L.tileLayer("https://tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=6d03ad810031442c8f14437fd26551d4", {
      attribution: [attrThunderforest, attrOverpass].join(", "),
      maxZoom: 19,
    });


    const postboxes = new L.OverPassLayer({
      query: "nw[amenity=post_box]({{bbox}});out center;",
      minZoom: 14,
      markerIcon: L.AwesomeMarkers.icon({
        prefix: "fa",
        icon: "envelope",
        markerColor: "orange",
      }),
    });

    const toilets = new L.OverPassLayer({
      query: "nw[amenity=toilets]({{bbox}});out center;",
      minZoom: 14,
      markerIcon: L.AwesomeMarkers.icon({
        prefix: "fa",
        icon: "restroom",
        markerColor: "blue",
      }),
    });

    const farmshops = new L.OverPassLayer({
      query: "nw['shop'='farm']({{bbox}});out center;",
      minZoom: 13,
      markerIcon: L.AwesomeMarkers.icon({
        prefix: "fa",
        icon: "carrot",
        markerColor: "green",
      }),
    });

    const ebikeCharging = new L.OverPassLayer({
      query:
        'nw["amenity"="charging_station"]["bicycle"="yes"]({{bbox}});out center;',
      minZoom: 12,
      markerIcon: L.AwesomeMarkers.icon({
        prefix: "fa",
        icon: "bicycle",
        markerColor: "purple",
      }),
    });

    const chargingStation = new L.OverPassLayer({
      query: 'nw["amenity"="charging_station"]({{bbox}});out center;',
      minZoom: 12,
      markerIcon: L.AwesomeMarkers.icon({
        prefix: "fa",
        icon: "charging-station",
        markerColor: "purple",
      }),
    });

    const restaurant = new L.OverPassLayer({
      query: 'nw["amenity"="restaurant"]({{bbox}});out center;',
      minZoom: 15,
      markerIcon: L.AwesomeMarkers.icon({
        prefix: "fa",
        icon: "utensils",
        markerColor: "red",
      }),
    });

    const parking = new L.OverPassLayer({
      query: 'nw["amenity"="parking"]({{bbox}});out center;',
      minZoom: 14,
      markerIcon: L.AwesomeMarkers.icon({
        prefix: "fa",
        icon: "parking",
        markerColor: "darkblue",
      }),
    });

    const glassRecycling = new L.OverPassLayer({
      query:
        '(nw["amenity"="recycling"]["recycling:glass_bottles"="yes"]({{bbox}});nw["amenity"="recycling"]["recycling:glass"="yes"]({{bbox}});); out center;',
      minZoom: 15,
      markerIcon: L.AwesomeMarkers.icon({
        prefix: "fa",
        icon: "wine-bottle",
        markerColor: "green",
      }),
    });

    const organic = new L.OverPassLayer({
      query:
        '(nw["shop"="organic"]({{bbox}});nw["shop"~"(supermarket|convenience)"]["organic"~"(yes|only)"]({{bbox}});); out center;',
      minZoom: 14,
      markerIcon: L.AwesomeMarkers.icon({
        prefix: "fa",
        icon: "seedling",
        markerColor: "green",
      }),
    });

    const bench = new L.OverPassLayer({
      query: '(nw["amenity"="bench"]({{bbox}});nw["tourism"="picnic_site"]({{bbox}});nw["amenity"="shelter"]["shelter_type"="picnic_shelter"]({{bbox}}););out center;',
      minZoom: 15,
      markerIcon: L.AwesomeMarkers.icon({
        prefix: "fa",
        icon: "chair",
        markerColor: "darkblue",
      }),
    });

    const raststaetten = new L.OverPassLayer({
      query: 'nwr["highway"="services"]({{bbox}});out center;',
      minZoom: 10,
      markerIcon: L.AwesomeMarkers.icon({
        prefix: "fa",
        icon: "utensils",
        markerColor: "darkblue",
      }),
    });

    const autobahn = new AutobahnLayer();

    const baseMaps = {
      "Standard OSM": osmLayer,
      "OSM German labels": osmDeLayer,
      "OSM public transport": osmPublicTransportLayer,
      "Outdoor": thunderforestOutdoor,
      "OpenCycleMap": thunderforestOpenCycleMap,
      "Landscape": thunderforestLandscape
    };

    const overlayMaps = {
      Briefkästen: L.layerGroup([postboxes]),
      Toiletten: L.layerGroup([toilets]),
      Hofläden: L.layerGroup([farmshops]),
      Ladestation: L.layerGroup([chargingStation]),
      "E-Bike Ladestation": L.layerGroup([ebikeCharging]),
      Restaurant: L.layerGroup([restaurant]),
      Parkplatz: L.layerGroup([parking]),
      Altglascontainer: L.layerGroup([glassRecycling]),
      Bioladen: L.layerGroup([organic]),
      Parkbank: L.layerGroup([bench]),
      Raststätten: L.layerGroup([raststaetten]),
      "Autobahn Webcams": autobahn,
    };

    L.control.layers(baseMaps, overlayMaps).addTo(map);
    L.control.locate().addTo(map);
    L.Control.geocoder({
      placeholder: "Suche ...",
      geocoder: L.Control.Geocoder.photon(),
    }).addTo(map);
    L.control.scale({position:"topright", imperial: false}).addTo(map);
    L.control.polylineMeasure({showClearControl: true}).addTo(map);
  },
});
