// For API auf Autobahn App see https://autobahn.api.bund.dev/

import axios from 'axios'
import L from "leaflet";

export class AutobahnLayer extends L.LayerGroup {
    roads = []

    constructor() {
        super();
        this.on('add', this.addElements);
        this.on('remove', this.removeElements);
    }

    // eslint-disable-next-line
    addElements(e: any) {
        if (this.roads.length == 0) {
            axios.get('https://verkehr.autobahn.de/o/autobahn/')
                .then(response => {
                    this.roads = response.data.roads;
                    this.collectWebcams();
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            this.collectWebcams();
        }
        return
    }

    // eslint-disable-next-line
    removeElements(e: any) {
        const layers = this.getLayers();
        for (const l of layers) {
            this.removeLayer(l)
        }
        return
    }

    collectWebcams() {
        for (const road of this.roads) {
            axios.get(`https://verkehr.autobahn.de/o/autobahn/${road}/services/webcam`)
                .then(response => {
                    for (const webcam of response.data.webcam) {
                        this.createWebcamMarker(webcam);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    // eslint-disable-next-line
    createWebcamMarker(webcam: any) {
        const marker = L.marker([webcam.coordinate.lat, webcam.coordinate.long]);
        let popup = ""
        //popup += `<p>${JSON.stringify(webcam)}</p>`;
        if (webcam.title) {
            popup += `<p>${webcam.title}</p>`
        }
        if (webcam.subtitle) {
            popup += `<p>${webcam.subtitle}</p>`
        }
        if (webcam.linkurl) {
            popup += `<p><a target="_blank" href="${webcam.linkurl}">Live</a></p>`
        }
        if (webcam.imageurl) {
            popup += `<p><a target="_blank" href="${webcam.imageurl}"><img src="${webcam.imageurl}" style="width:300px"></a></p>`
        }
        marker.bindPopup(popup)
        this.addLayer(marker);
    }

}
